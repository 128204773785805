import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/clients`

export default {
  client() {
    return {
      /**
       * Search/Get all clients
       * @param {Object} param0 offset, limit
       * @param {Boolean} searching if request is a search
       * @param {Object} param2 searchBy, keyword
       * @returns Promise
       */
      getAll: async (
        { offset = 0, limit = 0 },
        searching = false,
        data = {}
      ) => {
        let endpoint = ''
        if (searching) {
          endpoint = `${URL}?limit=0&keyword=${data.keyword}&orderBy=lastName&order=asc`
        } else {
          endpoint = `${URL}?offset=${offset}&limit=${limit}&orderBy=lastName&order=asc`
        }
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAllWithLimit: async ({ limit = 0 }) => {
        const endpoint = `${URL}?limit=${limit}&orderBy=lastName&order=asc`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAClient: async ({ clientId }) => {
        const endpoint = `${URL}/${clientId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      addClient: async data => {
        const endpoint = `${URL}`
        const formData = new FormData()
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element)
          }
        }
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters.getRequestHeaders.headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateClient: async ({ clientId }, data) => {
        const endpoint = `${URL}/${clientId}`
        const formData = new FormData()
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element)
          }
        }
        try {
          const res = await axios.put(endpoint, formData, {
            headers: {
              ...Store.getters.getRequestHeaders.headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteAUser: async ({ clientId }) => {
        const endpoint = `${URL}/${clientId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendEmail: async ({ clientIds, title, message }) => {
        const endpoint = `${URL}/send-mail`
        try {
          const res = await axios.post(
            endpoint,
            { clientIds, title, message },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      uploadDocument: async ({ clientId, title, file }) => {
        const endpoint = `${URL}/files`
        const formData = new FormData()
        formData.append('clientId', clientId)
        formData.append('title', title)
        formData.append('file', file)
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters.getRequestHeaders.headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      fetchDocument: async ({ clientId }) => {
        const endpoint = `${URL}/files?client=${clientId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      downloadDocument: async ({ fileId }) => {
        const endpoint = `${URL}/files/${fileId}/download`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteDocument: async ({ fileId }) => {
        const endpoint = `${URL}/files/${fileId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendSms: async ({ clientIds, message }) => {
        const endpoint = `${URL}/send-sms`
        try {
          const res = await axios.post(
            endpoint,
            { clientIds, message },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      exportClients: async () => {
        const endpoint = `${URL}/export-clients`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
