<template>
  <p class="font-semibold text-sm text-gray-800 mb-5">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'SectionSubtitle'
}
</script>

<style></style>
